<template>
  <div class="home">
    <div class="highlight">
      <div class="text is-size-4">
        <h1 class="title">
          {{ config.title }}
        </h1>
        <h2 class="subtitle">
          {{ config.home.subtitle }}
        </h2>
        <p
          v-for="(paragraph, idx) in config.home.paragraphs" 
          :key="`home-p-${idx}`"
          class="has-text-grey"
          v-html="paragraph"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss">
.hero.is-bold {
  position: relative;
  z-index: 10;
  background-color: white;
}
.tabs.is-centered.is-large {
  position: relative;
  z-index: 10;
  background-color: white;
}
</style>

<style lang="scss" scoped>
@import "bulma/sass/utilities/_all.sass";

.highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  .text {
    .title {
      font-weight: bold;
    }
    .subtitle {
      color: var(--color-accent);
      font-weight: bold;
    }
    p {
      margin-bottom: 2rem;
    }
    width: 90%;
    padding: 1rem;
    position: relative;
    z-index: 12;
    @include from($desktop) {
      width: 70%;
    }
    @include from($widescreen) {
      width: 60%;
    }
    @include from($fullhd) {
      width: 50%;
    }
    p:nth-child(1) {
      padding-bottom: 1em;
    }
  }
}
</style>
