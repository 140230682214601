import Vue from "vue";
import App from "./App.vue";
import Buefy from 'buefy';

const defaultConfig = {
  "title": "TSG Identity Provider",
  "logo": "/TSG-Logo-White.svg",
  "home": {
    "subtitle": "Identities for IDS entities",
    "paragraphs": [
      "The Identity Provider acts as an agent for the International Data Spaces Association. It is responsible for issuing technical identities to parties that have been approved to become Participants in the International Data Spaces. The Identity Provider is instructed to issue identities based on approved roles (e.g., App Store or App Provider). Only if equipped with such an identity, an entity is allowed to participate in the International Data Spaces (e.g., to provide data or publish Data Apps). The Identity Provider may exclude Participants from the International Data Spaces, if instructed to do so.",
      "As a trusted entity, the Identity Provider manages the PKI rollout. It takes care if certificates expire or must be revoked. There are two separate PKI hierarchies: one for software signatures (Software Signing Root CA) and one for the Connectors (Service Root CA). An entity is assigned with either an end certificate or a sub/root-CA certificate. The two hierarchies protect the interests of the six entities.",
      "The Identity Provider also acts as an authorization service (as described above) by incorporating the DAPS."
    ]
  },
  "term": {
    "participants": "Participants",
    "participant": "Participant",
    "components": "Connectors",
    "component": "Connector"
  },
  "prefixes": {
    "participant": "urn:tsg:participants:",
    "component": "urn:tsg:connectors:",
  },
  "contact": {
    "required": true
  },
  "footer": {
    "logo": "/TSG-Logo-White.svg",
    "copyright": "&copy; 2021 TNO - TSG"
  }
}

fetch('config/config.json')
  .then(response => {
    return response.json();
  }).then((config) => {
    const mergedConfig = {...defaultConfig, ...config}
    console.log(mergedConfig);

    Vue.config.productionTip = false;

    Vue.use(Buefy);
    
    Vue.mixin({
      data() {
        return {
          config: mergedConfig
        }
      },
      methods: {
        download (filename, text) {
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
          );
          element.setAttribute("download", filename);
        
          element.style.display = "none";
          document.body.appendChild(element);
        
          element.click();
        
          document.body.removeChild(element);
        }
      }
    });
    
    new Vue({
      render: h => h(App)
    }).$mount("#app");
});