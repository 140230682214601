<template>
  <b-table :data="users">
    <b-table-column
      v-slot="props"
      field="username"
      label="Username"
    >
      {{ props.row.username }} <span v-if="props.row.username !== props.row.email">({{ props.row.email }})</span>
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="name"
      label="Name"
    >
      {{ props.row.name }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="role"
      label="Role"
    >
      <span class="pr-3">USER</span>
      <b-switch
        v-model="props.row.role"
        true-value="ADMIN"
        false-value="USER"
        @input="(value) => roleChange(props.row.username, value)"
      >
        ADMIN
      </b-switch>
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="verified"
      label="Verified"
    >
      <b-icon 
        v-if="props.row.verification.verified"
        icon="check"
        type="is-success"
      />
      <b-icon
        v-else
        icon="cancel"
        type="is-danger"
      />
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="actions"
      label="Actions"
    >
      <button
        v-if="!props.row.verification.verified"
        class="button is-action is-size-7 is-outlined"
        @click="verifyUser(props.row.username, props.row.verification.code)"
      >
        Verify
      </button>
      <button
        class="button is-danger is-size-7 is-outlined"
        @click="deleteUser(props.row.username)"
      >
        Delete
      </button>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  props: {
    api: {
      type: Function,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  methods: {
    async roleChange (username, role) {
      try {
        await this.api.put(`users/updateRole`, null, {
          params: {
            username: username,
            role: role
          },
        });
      } catch (e) {
        this.$buefy.toast.open({
          message: `Role change failed!`,
          duration: 10000,
          type: 'is-danger',
        });
      }
      this.$emit("refresh");
    },
    deleteUser (username) {
      this.$buefy.dialog.confirm({
        message:
          "Are you sure you want to permanently delete this federated DAPS entry?",
        onConfirm: async () => {
          console.log(`Delete user: ${username}`);
          await this.api.delete(`users`, {
            params: {
              username: username,
            },
          });
          this.$emit("refresh");
        },
      });
    },
    async verifyUser (username, code) {
      try {
        await this.api.put(`verifyuser`, {
          user: username,
          code: code
        })
      } catch (e) {
        this.$buefy.toast.open({
          message: `Verification failed!`,
          duration: 5000,
          type: 'is-danger',
        });
      }
      this.$emit("refresh");
    }
  }
}
</script>