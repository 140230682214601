<template>
  <b-table
    :data="components"
    :paginated="true"
    :per-page="25"
    detailed
    detail-key="idsid"
  >
    <b-table-column
      v-slot="props"
      field="name"
      label="Name"
    >
      {{ props.row.name }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="idsid"
      :label="`${config.term.component} ID`"
    >
      {{ props.row.idsid }}
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="participant"
      :label="`${config.term.participant} ID`"
    >
      {{ props.row.participant }}
    </b-table-column>
    <b-table-column
      v-if="role === 'ADMIN'"
      v-slot="props"
      field="contact"
      label="Contact"
    >
      {{ props.row.contact }} (<a :href="'mailto:' + props.row.email">{{
        props.row.email
      }}</a>)
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="status"
      label="Status"
    >
      <div>
        <span
          v-if="props.row.claim.componentCertification.active"
          class="has-text-weight-bold has-text-success"
        >
          {{ props.row.claim.componentCertification.securityProfile }}
          Security Profile
        </span>
        <span
          v-else
          class="has-text-weight-bold has-text-danger"
        >Not certified</span>
        <span
          v-if="props.row.claim.componentCertification.active"
          class="is-size-7 is-block"
        >
          {{ props.row.claim.componentCertification.lastActive | dateFilter }}
        </span>
      </div>
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="actions"
      label="Actions"
    >
      <div class="buttons is-flex-wrap-nowrap">
        <b-button
          type="is-danger"
          outlined
          icon-left="delete"
          class="mr-1"
          @click="deleteComponent(props.row.subject)"
        />
        <b-button
          type="is-success"
          outlined
          icon-left="download"
          @click="download('component.crt', props.row.certificate)"
        />
      </div>
    </b-table-column>
    <template #detail="props">
      <div class="tags">
        <span
          v-for="(item, idx) in props.row.subjectattr"
          :key="`${props.row.name}-${idx}`"
          class="tag"
        >
          {{ item.name }}={{ item.value }}
        </span>
      </div>
      <div
        v-if="role === 'ADMIN'"
        class="buttons"
      >
        <b-button
          v-if="props.row.claim.componentCertification.active"
          type="is-danger"
          size="is-small"
          outlined
          @click="certifyComponent(props.row.subject, false, 'Base')"
        >
          Revoke
        </b-button>
        <b-button
          v-if="
            !props.row.claim.componentCertification.active ||
              props.row.claim.componentCertification.securityProfile !== 'Base'
          "
          type="is-success"
          size="is-small"
          outlined
          @click="certifyComponent(props.row.subject, true, 'Base')"
        >
          Base
        </b-button>
        <b-button
          v-if="
            props.row.claim.componentCertification.securityProfile !== 'Trust'
          "
          type="is-success"
          size="is-small"
          outlined
          @click="certifyComponent(props.row.subject, true, 'Trust')"
        >
          Trust
        </b-button>
        <b-button
          v-if="
            props.row.claim.componentCertification.securityProfile !== 'Trust+'
          "
          type="is-success"
          size="is-small"
          outlined
          @click="certifyComponent(props.row.subject, true, 'Trust+')"
        >
          Trust+
        </b-button>
      </div>
    </template>
    <template #empty>
      <div class="has-text-centered">
        No registered {{ config.term.component }} certificates
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  filters: {
    dateFilter: function (isoDate) {
      return isoDate.slice(0, -5).replace("T", " ");
    },
  },
  props: {
    api: {
      type: Function,
      required: true,
    },
    components: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true
    }
  },
  methods: {
    async certifyComponent(component, certify, securityProfile) {
      await this.api.post("components/certify", {
        subject: component,
        certify: certify,
        securityProfile: securityProfile,
      });
      this.fetchComponents();
    },
    async deleteComponent(component) {
      this.$buefy.dialog.confirm({
        message:
          `Are you sure you want to permanently delete this ${this.config.term.component} certificate?`,
        onConfirm: async () => {
          await this.api.delete(`components/${component}`);
          this.$emit("refresh");
        },
      });
    }
  },
};
</script>