<template>
  <div>
    <b-table :data="federation">
      <b-table-column
        v-slot="props"
        field="dapsid"
        label="DAPS ID"
      >
        {{ props.row.dapsid }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="url"
        label="URL"
      >
        {{ props.row.url }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="trusted"
        label="Trusted"
      >
        <b-icon 
          v-if="props.row.trusted"
          icon="check"
          type="is-success"
        />
        <b-icon
          v-else
          icon="cancel"
          type="is-danger"
        />
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="actions"
        label="Actions"
      >
        <button
          class="button is-size-7 is-outlined mr-2"
          :class="{
            'is-danger': props.row.trusted,
            'is-success': !props.row.trusted,
          }"
          @click="changeFederationTrust(props.row.dapsid, !props.row.trusted)"
        >
          <template v-if="props.row.trusted">
            Untrust
          </template>
          <template v-else>
            Trust
          </template>
        </button>
        <button
          class="button is-danger is-size-7 is-outlined"
          @click="deleteFederatedDaps(props.row.dapsid)"
        >
          Delete
        </button>
      </b-table-column>
    </b-table>
    <h3 class="title mt-6">
      Add/modify Federated DAPS
    </h3>
    <form
      ref="federationDapsForm"
      @submit="federationDapsForm"
    >
      <b-field
        horizontal
        label="Create/update"
      >
        <b-radio
          v-model="federationForm.operation"
          name="operation"
          native-value="create"
        >
          Create
        </b-radio>
        <b-radio
          v-model="federationForm.operation"
          name="operation"
          native-value="update"
        >
          Update
        </b-radio>
      </b-field>
      <b-field
        horizontal
        label="DAPS ID"
        message="As used in token issuer field"
      >
        <b-input
          v-model="federationForm.dapsid"
          placeholder="DAPS ID"
          required
        />
      </b-field>
      <b-field
        horizontal
        label="DAPS JWKS URL"
        message="Usually in the form of {DAPS_ID}/.well-known/jwks.json"
      >
        <b-input
          v-model="federationForm.url"
          placeholder="DAPS JWKS URL"
          required
        />
      </b-field>
      <b-field horizontal>
        <b-button
          type="is-link"
          @click="federationDapsForm"
        >
          Submit
        </b-button>
      </b-field>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    api: {
      type: Function,
      required: true,
    },
    federation: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      federationForm: {
        operation: "create",
        dapsid: null,
        url: null,
      },
    };
  },
  methods: {
    async federationDapsForm(e) {
      if (!this.$refs.federationDapsForm.checkValidity()) {
        this.$buefy.toast.open({
          message: 'Please fill all required fields',
          duration: 10000,
          type: 'is-warning',
        });
        return;
      }
      e.preventDefault();
      if (this.federationForm.operation === "create") {
        await this.api.post(`federation`, {
          dapsid: this.federationForm.dapsid,
          url: this.federationForm.url,
        });
      } else if (this.federationForm.operation === "update") {
        await this.api.put(
          `federation`,
          {
            url: this.federationForm.url,
          },
          {
            params: {
              id: this.federationForm.dapsid,
            },
          }
        );
      }
      this.$emit("refresh");
    },
    async changeFederationTrust(dapsid, trust) {
      await this.api.put(
        `federation`,
        {
          trust: trust,
        },
        {
          params: {
            id: dapsid,
          },
        }
      );
      this.$emit("refresh");
    },
    async deleteFederatedDaps(dapsid) {
      this.$buefy.dialog.confirm({
        message:
          "Are you sure you want to permanently delete this federated DAPS entry?",
        onConfirm: async () => {
          await this.api.delete(`federation`, {
            params: {
              id: dapsid,
            },
          });
          this.$emit("refresh");
        },
      });
    },
  },
};
</script>