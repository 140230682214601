<template>
  <section
    class="section"
    :class="{ 'pt-0': loggedIn }"
  >
    <Login
      v-if="!loggedIn"
      @login="parseToken"
    />
    <template v-else>
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <p>Logged in as: {{ username }}</p>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-button
              type="is-success"
              @click="logout"
            >
              Logout
            </b-button>
          </div>
        </div>
      </div>

      <b-tabs position="is-centered">
        <b-tab-item :label="config.term.participants">
          <h2 class="title">
            {{ config.term.participants }}
          </h2>
          <ParticipantMgmtTable
            :api="api"
            :participants="participants"
            :role="role"
            :config="config"
            @refresh="fetchParticipants"
          />
          <ParticipantModal
            :api="api"
            @created="fetchParticipants"
          />
        </b-tab-item>
        <b-tab-item :label="config.term.components">
          <h2 class="title">
            {{ config.term.components }}
          </h2>
          <ComponentMgmtTable
            :api="api"
            :components="components"
            :role="role"
            @refresh="fetchComponents"
          />
          <ComponentModal
            :api="api"
            :participants="participants"
            @created="fetchComponents"
          />
        </b-tab-item>
        <b-tab-item
          label="Federated DAPS"
          :visible="role === 'ADMIN'"
        >
          <template v-if="role === 'ADMIN'">
            <h2 class="title">
              Federated DAPS
            </h2>
            <FederatedDaps
              :api="api"
              :federation="federation"
              @refresh="fetchFederatedDaps"
            />
          </template>
        </b-tab-item>
        <b-tab-item
          label="Users"
          :visible="role === 'ADMIN'"
        >
          <template v-if="role === 'ADMIN'">
            <h2 class="title">
              Users
            </h2>
            <UserMgmt
              :api="api"
              :users="users"
              @refresh="fetchUsers"
            />
          </template>
        </b-tab-item>
      </b-tabs>
    </template>
  </section>
</template>

<script>
import Login from "../components/Login.vue";
import axios from "axios";
import { pki } from "node-forge";
import ComponentModal from "../components/ComponentModal.vue";
import ParticipantModal from "../components/ParticipantModal.vue";
import ParticipantMgmtTable from "../components/ParticipantMgmtTable.vue";
import ComponentMgmtTable from "../components/ComponentMgmtTable.vue";
import FederatedDaps from "../components/FederatedDaps.vue";
import UserMgmt from '../components/UserMgmt.vue';

export default {
  components: {
    Login,
    ComponentModal,
    ParticipantModal,
    ParticipantMgmtTable,
    ComponentMgmtTable,
    FederatedDaps,
    UserMgmt,
  },
  filters: {
    dateFilter: function (isoDate) {
      return isoDate.slice(0, -5).replace("T", " ");
    },
  },
  data() {
    return {
      timer: "",
      api: null,
      role: undefined,
      username: undefined,
      loggedIn: false,
      participants: [],
      components: [],
      federation: [],
      users: []
    };
  },
  created: async function () {
    if (localStorage.token !== undefined) {
      this.parseToken(localStorage.token);
    }
  },
  methods: {
    parseToken(token) {
      const parsedToken = JSON.parse(atob(token.split(".")[1]));
      if (parsedToken.exp < new Date().getTime() / 1000 - 600) {
        localStorage.removeItem("token");
      }
      this.api = axios.create({
        baseURL: "/management/",
        timeout: 30000,
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      });
      this.role = parsedToken.role;
      this.username = parsedToken.sub;
      this.loggedIn = true;
      this.fetchInterval();
      this.timer = setInterval(this.fetchInterval, 30 * 1000);
    },
    logout() {
      localStorage.removeItem("token");
      this.api = undefined;
      this.role = undefined;
      this.username = undefined;
      this.loggedIn = false;
      this.participants = [];
      this.components = [];
      this.federation = [];
      this.users = [];
    },
    async fetchInterval() {
      const promises = [this.fetchParticipants(), this.fetchComponents()];
      if (this.role === "ADMIN") {
        promises.push(this.fetchFederatedDaps());
        promises.push(this.fetchUsers());
      }
      Promise.all(promises);
    },
    async fetchParticipants() {
      const participants = await this.api.get("participants");
      this.participants = participants.data.map((participant) => {
        participant.subjectattr = pki
          .certificateFromPem(participant.certificate)
          .subject.attributes.map((attr) => {
            return { name: attr.shortName, value: attr.value };
          });
        return participant;
      });
    },
    async fetchComponents() {
      const components = await this.api.get("components");
      this.components = components.data.map((component) => {
        component.subjectattr = pki
          .certificateFromPem(component.certificate)
          .subject.attributes.map((attr) => {
            return { name: attr.shortName, value: attr.value };
          });
        return component;
      });
    },
    async fetchFederatedDaps() {
      const federation = await this.api.get("federation");
      this.federation = federation.data;
    },
    async fetchUsers() {
      const users = await this.api.get("users");
      this.users = users.data;
    },
  },
};
</script>