<template>
  <div>
    <b-button
      size="is-large"
      icon-left="plus"
      type="is-success"
      outlined
      @click="isActive = true"
    >
      Request {{ config.term.component }} certificate
    </b-button>
    <div
      class="modal"
      :class="{ 'is-active': isActive }"
    >
      <div
        class="modal-background"
        @click="isActive = false"
      />
      <div
        class="modal-card"
        style="width: 80%"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">
            Request {{ config.term.component }} certificate
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="isActive = false"
          />
        </header>
        <section class="modal-card-body has-text-left">
          <div v-if="cert">
            <h2 class="title">
              Certificate
            </h2>
            <pre><code>{{ cert }}</code></pre>
            <h2
              v-if="cachain"
              class="title"
            >
              CA Chain
            </h2>
            <pre v-if="cachain"><code>{{ cachain }}</code></pre>
          </div>
          <form
            v-else
            ref="sendForm"
            @submit="sendForm"
          >
            <div class="columns">
              <div class="column">
                <b-field
                  horizontal
                  :label="config.term.participant"
                  required
                >
                  <b-select
                    v-model="participant"
                    :placeholder="config.term.participant"
                    expanded
                    required
                  >
                    <option
                      v-for="p in participants"
                      :key="p.id"
                      :value="p.id"
                    >
                      {{ p.id }}
                    </option>
                    <option
                      v-if="participants.length == 0"
                      disabled
                    >
                      A participant identity is required
                    </option>
                  </b-select>
                </b-field>
                <b-field
                  horizontal
                  :label="`${config.term.component} ID`"
                >
                  <b-field>
                    <b-input
                      v-model="id"
                      :placeholder="`${config.prefixes.component}...`"
                      :pattern="config.prefixes.componentRegex || 'https?://.*|urn:.*:.*'"
                      :validation-message="(config.prefixes.componentRegex) ? `Identifiers should start with ${config.prefixes.component}` : 'IDS Identifiers should be either URNs or URLs'"
                      required
                      expanded
                    />
                    <p class="control">
                      <span class="button is-static">IDS Identifier</span>
                    </p>
                  </b-field>
                </b-field>
                <b-field
                  horizontal
                  :label="`${config.term.component} name`"
                >
                  <b-input
                    v-model="name"
                    :placeholder="`${config.term.component} name`"
                    required
                  />
                </b-field>
              </div>
              <div class="column">
                <b-field label="Certificate Signing Request">
                  <b-input
                    v-model="csr"
                    type="textarea"
                    custom-class="is-family-monospace"
                    placeholder="-----BEGIN CERTIFICATE REQUEST-----"
                    required
                  />
                </b-field>
              </div>
            </div>
            <article class="message is-info">
              <div class="message-body">
                <p class="pb-3">
                  With this form you can get a signed certificate from the
                  <em>Component Sub CA</em>.
                  <template v-if="config.contact.required">
                    The certificate will be
                    automatically signed, but without a test certification
                    attached to it. 
                    
                    Contact 
                    <template v-if="config.contact.name">
                      <a :href="`mailto:${config.contact.email}`">{{ config.contact.name }}</a>
                    </template>
                    <template v-else>
                      the dataspace administrators
                    </template> to
                    activate the certificate with a test certification and to add
                    a new entry to the metadata broker.
                  </template>
                  <template v-else>
                    The certificate will be automatically signed and given an test certification, so your certificate is ready to use.
                  </template>
                </p>
                <p>
                  A certificate signing request can be created by the form below or by using OpenSSL:
                  <code>openssl req -new -newkey rsa:2048 -nodes -keyout
                    component.key -out component.csr</code>
                </p>
              </div>
            </article>
            <div>
              <h3
                class="title is-4 is-clickable"
                @click="createKeyForm.enabled = !createKeyForm.enabled"
              >
                <b-icon
                  v-if="createKeyForm.enabled"
                  icon="chevron-up"
                  size="is-small"
                />
                <b-icon
                  v-if="!createKeyForm.enabled"
                  icon="chevron-down"
                  size="is-small"
                />
                Create private key in browser
              </h3>
              <template v-if="createKeyForm.enabled">
                <p class="pb-5">
                  Creates an private key and certificate signing request locally within the browser. No information is shared over the internet about your key. Your key won't be stored anywhere, so make sure you download the key after creation
                </p>
                <form
                  ref="createKeyForm"
                  @submit.prevent="createKey"
                >
                  <div class="columns">
                    <div class="column">
                      <b-field
                        label-position="on-border"
                        label="Country"
                      >
                        <b-input
                          v-model="createKeyForm.country"
                          placeholder="NL"
                          required
                          @keyup.native="createKeyForm.country = createKeyForm.country.substr(0,2).toUpperCase()"
                          @keyup.native.enter="createKey"
                        />
                      </b-field>
                      <b-field
                        label-position="on-border"
                        label="State/Province"
                      >
                        <b-input
                          v-model="createKeyForm.state"
                          placeholder="State"
                          required
                          @keyup.native.enter="createKey"
                        />
                      </b-field>
                      <b-field
                        label-position="on-border"
                        label="City/Locality"
                      >
                        <b-input
                          v-model="createKeyForm.locality"
                          placeholder="City"
                          required
                          @keyup.native.enter="createKey"
                        />
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field
                        label-position="on-border"
                        label="Organization"
                      >
                        <b-input
                          v-model="createKeyForm.organization"
                          placeholder="Organization"
                          required
                          @keyup.native.enter="createKey"
                        />
                      </b-field>
                      <b-field
                        label-position="on-border"
                        label="Organizational Unit"
                      >
                        <b-input
                          v-model="createKeyForm.organizationalUnit"
                          placeholder="Organizational Unit"
                          @keyup.native.enter="createKey"
                        />
                      </b-field>
                      <b-field
                        label-position="on-border"
                        label="Common Name"
                      >
                        <b-input
                          v-model="createKeyForm.commonName"
                          :placeholder="`${config.term.component} name`"
                          required
                          @keyup.native.enter="createKey"
                        />
                      </b-field>
                    </div>
                  </div>
                  <b-field>
                    <b-button
                      type="is-link"
                      class="mr-4"
                      :loading="creatingKey"
                      @click="createKey"
                    >
                      Create key
                    </b-button>
                    <b-button
                      v-if="key"
                      type="is-success"
                      @click="download('component.key', key)"
                    >
                      Download key
                    </b-button>
                  </b-field>
                </form>
              </template>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <template v-if="cert === null">
            <button
              class="button is-success"
              @click="sendForm"
            >
              Request signed certificate
            </button>
            <button
              class="button"
              @click="isActive = false"
            >
              Cancel
            </button>
          </template>
          <template v-else>
            <button
              class="button is-success"
              @click="download('cachain.crt', cachain)"
            >
              Download CA chain
            </button>
            <button
              class="button is-success"
              @click="download('component.crt', cert)"
            >
              Download certificate
            </button>
            <button
              v-if="key"
              class="button is-success"
              @click="download('component.key', key)"
            >
              Download private key
            </button>
            <button
              class="button"
              @click="isActive = false"
            >
              Close
            </button>
          </template>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { pki } from 'node-forge';
export default {
  props: {
    api: {
      type: Function,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isActive: false,
      id: null,
      participant: null,
      name: null,
      contact: null,
      email: null,
      csr: null,
      cert: null,
      cachain: null,
      key: null,
      creatingKey: false,
      createKeyForm: {
        enabled: false,
        country: undefined,
        state: undefined,
        locality: undefined,
        organization: undefined,
        organizationalUnit: undefined,
        commonName: undefined
      }
    };
  },
  async created() {
    this.fetchCA();
  },
  methods: {
    fetchCA: async function () {
      let cachain = await this.api.get("ca");
      this.cachain = cachain.data;
    },
    sendForm: async function (e) {
      if (!this.$refs.sendForm.checkValidity()) {
        this.$buefy.toast.open({
          message: 'Please fill all required fields',
          duration: 10000,
          type: 'is-warning',
        });
        return;
      }
      e.preventDefault();
      const csrResponse = await this.api.post("components", {
        participant: this.participant,
        id: this.id,
        name: this.name,
        csr: this.csr,
      });
      if (csrResponse.data.error !== undefined) {
        this.$buefy.toast.open({
          message: `Could not sign Certificate Signing Request\n\n${csrResponse.data.error}`,
          duration: 10000,
          type: 'is-danger',
        });
        return;
      }
      this.$emit("created");
      this.cert = csrResponse.data.certificate;
      console.log("CSR Response:");
      console.log(csrResponse);
    },
    createKey: function() {
      if (!this.$refs.createKeyForm.checkValidity()) {
        this.$buefy.toast.open({
          message: 'Please fill all required fields',
          duration: 10000,
          type: 'is-warning',
        });
        return;
      }
      this.creatingKey = true;
      try {
        const keyPair = pki.rsa.generateKeyPair(2048);
        let csr = pki.createCertificationRequest();
        csr.publicKey = keyPair.publicKey;
        csr.setSubject([
          {
            name: 'countryName',
            value: this.createKeyForm.country.toUpperCase()
          },
          {
            name: 'stateOrProvinceName',
            value: this.createKeyForm.state
          },
          {
            name: 'localityName',
            value: this.createKeyForm.locality
          },
          {
            name: 'organizationName',
            value: this.createKeyForm.organization
          },
          {
            name: 'organizationalUnitName',
            value: this.createKeyForm.organizationalUnit
          },
          {
            name: 'commonName',
            value: this.createKeyForm.commonName
          }
        ]);
        csr.sign(keyPair.privateKey);
        const rsaPrivateKey = pki.privateKeyToAsn1(keyPair.privateKey);
        const wrapped = pki.wrapRsaPrivateKey(rsaPrivateKey);
        this.key = pki.privateKeyInfoToPem(wrapped);
        this.csr = pki.certificationRequestToPem(csr);
      } catch (e) {
        this.$buefy.toast.open({
          message: 'Creating key failed!',
          duration: 10000,
          type: 'is-danger',
        });
        console.log(e);
      }
      this.creatingKey = false;
    },
    download: function (filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>