<template>
  <section class="section">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h2 class="title is-4">
            {{ config.term.component }} Identities
          </h2>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a @click="() => {search = !search}"><b-icon
            icon="magnify"
          />
          </a>
        </div>
      </div>
    </div>
    <b-table
      :data="certificates.components"
      :paginated="true"
      :per-page="25"
      default-sort="name"
    >
      <b-table-column
        v-slot="props"
        field="name"
        label="Name"
        sortable
        :searchable="search"
      >
        {{ props.row.name }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="idsid"
        :label="`${config.term.component} ID`"
        sortable
        :searchable="search"
      >
        {{ props.row.idsid }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="participant"
        :label="`${config.term.participant} ID`"
        sortable
        :searchable="search"
      >
        {{ props.row.participant }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="contact"
        label="Contact"
        sortable
        :searchable="search"
      >
        {{ props.row.contact }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="claim.componentCertification.active"
        label="Certification"
        sortable
      >
        <span class="tag is-light is-medium">
          <b-icon 
            v-if="props.row.claim.componentCertification.active"
            icon="check"
            type="is-success"
          />
          <b-icon
            v-else
            icon="cancel"
            type="is-danger"
          />
          <span v-if="props.row.claim.componentCertification.active">{{
            props.row.claim.componentCertification.securityProfile
          }}
            Security Profile</span>
        </span>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
export default {
  props: {
    api: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      timer: "",
      certificates: {},
      search: false
    };
  },
  created: async function () {
    this.fetchCertificates();
    this.timer = setInterval(this.fetchCertificates, 30 * 1000);
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
  methods: {
    fetchCertificates: async function () {
      let certificates = await this.api.get("certs");
      this.certificates = certificates.data;
    }
  }
};
</script>