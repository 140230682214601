<template>
  <div>
    <section class="hero is-bold">
      <div class="hero-body py-2">
        <div class="container">
          <div class="level">
            <div class="level-left">
              <figure class="image">
                <img :src="config.logo">
              </figure>
            </div>
            <div class="level-left">
              <h1
                v-if="tab !== 'home'"
                class="title"
              >
                {{ config.title }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="tabs is-centered is-large">
      <ul>
        <li
          :class="{ 'is-active': tab === 'home' }"
          @click="tab = 'home'"
        >
          <a>Home</a>
        </li>
        <li
          :class="{ 'is-active': tab === 'participants' }"
          @click="tab = 'participants'"
        >
          <a>{{ config.term.participants }}</a>
        </li>
        <li
          :class="{ 'is-active': tab === 'connectors' }"
          @click="tab = 'connectors'"
        >
          <a>{{ config.term.components }}</a>
        </li>
        <li
          :class="{ 'is-active': tab === 'management' }"
          @click="tab = 'management'"
        >
          <a>Management</a>
        </li>
        <li
          v-if="adminCredentials.username !== ''"
          :class="{ 'is-active': tab === 'admin' }"
          @click="tab = 'admin'"
        >
          <a>Admin</a>
        </li>
      </ul>
    </div>
    <div :class="{ container: tab !== 'home' }">
      <Home v-if="tab === 'home'" />
      <Participants
        v-if="tab === 'participants'"
        :api="api"
      />
      <Connectors
        v-if="tab === 'connectors'"
        :api="api"
      />
      <Management v-if="tab === 'management'" />
      <Admin
        v-if="tab === 'admin'"
        :credentials="adminCredentials"
        @credentialChange="adminCredentialsChange"
      />
    </div>
    <footer class="footer">
      <div class="content has-text-centered has-text-white">
        <img :src="config.footer.logo">
        <p v-html="config.footer.copyright" />
      </div>
    </footer>
  </div>
</template>

<script>
import Participants from "./views/Participants.vue";
import Connectors from "./views/Connectors.vue";
import Management from "./views/Management.vue";
import Home from "./views/Home.vue";

import axios from "axios";
import "@fortawesome/fontawesome-free/js/all.js";

export default {
  components: {
    Participants,
    Connectors,
    Management,
    Home,
  },
  data() {
    return {
      api: axios.create({
        baseURL: "/",
        timeout: 5000,
      }),
      tab: window.location.hash.replace("#", "") || "home",
      adminCredentials: {
        username: "",
        password: "",
      },
    };
  },
  watch: {
    tab: function (newTab) {
      window.location.hash = newTab;
    },
  },
  mounted() {
    if (this.config.colors) {
      Object.entries(this.config.colors).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--color-${key}`, value);
      })
    }
    if (this.config.favicon) {
      document.querySelector("link[rel=icon]").href = this.config.favicon;
    }
    if (this.config.title) {
      document.querySelector("title").innerHTML = this.config.title
    }
  },
  created: async function () {
    if (
      localStorage.username !== undefined &&
      localStorage.password !== undefined
    ) {
      this.adminCredentials.username = localStorage.username;
      this.adminCredentials.password = localStorage.password;
    }
  },
  beforeDestroy: function () {
    clearInterval(this.connectorTimer);
  },
  methods: {
    adminCredentialsChange: function (credentials) {
      localStorage.username = credentials.username;
      localStorage.password = credentials.password;
      this.adminCredentials = credentials;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");

// $tno-dark: #222;
// $tno-blue: #72a7d1;

:root {
  --color-accent: #72a7d1;
  --color-accent-darken: #5997c9;
  --color-dark: #222;
  --color-header: black;
  --color-body: white;
  --color-hero-text: white;
}

$family-sans-serif: "Open Sans", sans-serif;
// $text-strong: $tno-dark;
$tabs-link-color: #{var(--color-accent)};
$tabs-link-active-color: #{var(--color-dark)};
$tabs-link-active-border-bottom-color: #{var(--color-dark)};
$tabs-link-hover-color: #{var(--color-accent-darken)};
$tabs-link-hover-border-bottom-color: #{var(--color-accent-darken)};
// $link: $tno-dark;
// $dark: $tno-dark;
$footer-background-color: #{var(--color-accent)};
// $table-row-active-color: $tno-blue;
// $info: $tno-blue;

@import "~bulma/bulma";
@import "~buefy/src/scss/buefy";

html {
  background-color: var(--color-accent); //$tno-blue;
}

body {
  background-color: var(--color-body);
}

.hero {
  &.is-bold {
    background-color: var(--color-header);
  }
  img {
    max-height: 4rem;
    @include from($tablet) {
      max-height: 9rem;
    }
  }
  .title {
    color: var(--color-hero-text);
    font-size: 3rem;
    text-align: center;
    @include from($tablet) {
      text-align: left;
    }
  }
}

.footer {
  position: relative;
  z-index: 20;
  > .content {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      padding-right: 3em;
      max-height: 10rem;

      @include from($desktop) {
        padding-right: 5em;
      }
    }
  }
}
</style>