<template>
  <b-table
    :data="participants"
    :paginated="true"
    :per-page="25"
    detailed
    detail-key="id"
  >
    <b-table-column
      v-slot="props"
      field="id"
      :label="`${config.term.participant} ID`"
    >
      {{ props.row.id }}
    </b-table-column>
    <b-table-column
      v-if="role === 'ADMIN'"
      v-slot="props"
      field="contact"
      label="Contact"
    >
      {{ props.row.contact }} (<a :href="'mailto:' + props.row.email">{{
        props.row.email
      }}</a>)
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="status"
      label="Status"
    >
      <div>
        <span
          v-if="props.row.certification.active"
          class="has-text-weight-bold has-text-success"
        >{{ props.row.certification.level }} level</span>
        <span
          v-else
          class="has-text-weight-bold has-text-danger"
        >Not certified</span>
        <span
          v-if="props.row.certification.active"
          class="is-size-7 is-block"
        >{{ props.row.certification.lastActive | dateFilter }}</span>
      </div>
    </b-table-column>
    <b-table-column
      v-slot="props"
      field="actions"
      label="Actions"
    >
      <div class="buttons is-flex-wrap-nowrap">
        <b-button
          type="is-danger"
          outlined
          icon-left="delete"
          class="mr-1"
          @click="deleteParticipant(props.row.id)"
        />
        <b-button
          type="is-success"
          outlined
          icon-left="download"
          @click="download('participant.crt', props.row.certificate)"
        />
      </div>
    </b-table-column>
    <template #detail="props">
      <div class="tags">
        <span
          v-for="(item, idx) in props.row.subjectattr"
          :key="`${props.row.name}-${idx}`"
          class="tag"
        >
          {{ item.name }}={{ item.value }}
        </span>
      </div>
      <div
        v-if="role === 'ADMIN'"
        class="buttons"
      >
        <b-button
          v-if="props.row.certification.active"
          type="is-danger"
          outlined
          @click="certifyParticipant(props.row.id, false, '')"
        >
          Revoke
        </b-button>
        <b-button
          v-if="
            !props.row.certification.active ||
              props.row.certification.level !== 'Entry'
          "
          type="is-success"
          outlined
          @click="certifyParticipant(props.row.id, true, 'Entry')"
        >
          Entry
        </b-button>
        <b-button
          v-if="props.row.certification.level !== 'Member'"
          type="is-success"
          outlined
          @click="certifyParticipant(props.row.id, true, 'Member')"
        >
          Member
        </b-button>
        <b-button
          v-if="props.row.certification.level !== 'Central'"
          type="is-success"
          outlined
          @click="certifyParticipant(props.row.id, true, 'Central')"
        >
          Central
        </b-button>
      </div>
    </template>
    <template #empty>
      <div class="has-text-centered">
        No registered {{ config.term.participant }} certificates
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  filters: {
    dateFilter: function (isoDate) {
      return isoDate.slice(0, -5).replace("T", " ");
    },
  },
  props: {
    api: {
      type: Function,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      required: true
    }
  },
  methods: {
    async certifyParticipant(participant, certify, level) {
      await this.api.post("participants/certify", {
        participant: participant,
        certify: certify,
        level: level,
      });
      this.fetchParticipants();
    },
    async deleteParticipant(participant) {
      console.log(this.config)
      this.$buefy.dialog.confirm({
        message:
          `Are you sure you want to permanently delete this ${this.config.term.participant} certificate?`,
        onConfirm: async () => {
          await this.api.delete(
            `participants/${encodeURIComponent(participant)}`
          );
          this.$emit("refresh");
        },
      });
    }
  },
};
</script>