<template>
  <section class="section">
    <h2 class="title is-4">
      {{ config.term.participant }} Identities
    </h2>
    <b-table
      :data="certificates.participants"
      :paginated="true"
      :per-page="25"
    >
      <b-table-column
        v-slot="props"
        field="id"
        :label="`${config.term.participant} ID`"
      >
        {{ props.row.id }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="contact"
        label="Contact"
      >
        {{ props.row.contact }}
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="certification"
        label="Certification"
      >
        <span class="tag is-light is-medium">
          <b-icon 
            v-if="props.row.certification.active"
            icon="check"
            type="is-success"
          />
          <b-icon
            v-else
            icon="cancel"
            type="is-danger"
          />
          <span v-if="props.row.certification.active">{{ props.row.certification.level }} Level</span>
        </span>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
export default {
  props: {
    api: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      timer: "",
      certificates: {},
    };
  },
  created: async function () {
    this.fetchCertificates();
    this.timer = setInterval(this.fetchCertificates, 30 * 1000);
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
  methods: {
    fetchCertificates: async function () {
      let certificates = await this.api.get("certs");
      this.certificates = certificates.data;
    },
  },
};
</script>